import React from "react";
import CategoryCard from "../../compenents/CategoryCard";
import art from "../../assets/categories/art.jpg";
import baking from "../../assets/categories/baking.jpg";
import boutique from "../../assets/categories/boutique.jpg";
import clothing from "../../assets/categories/clothing.jpg";
import fashion from "../../assets/categories/fashion.jpg";
import financialServices from "../../assets/categories/financialServices.jpg";
import fitness from "../../assets/categories/fitness.jpg";
import foodDining from "../../assets/categories/foodDining.jpg";
import homemadeFood from "../../assets/categories/homemadeFood.jpg";
import garden from "../../assets/categories/garden.jpg";
import interior from "../../assets/categories/interior.jpg";
import weddingDecoration from "../../assets/categories/weddingDecoration.jpg";
import teaching from "../../assets/categories/teaching.jpeg";
import others from "../../assets/categories/others.jpeg";
import { useNavigate } from "react-router-dom";

const BusinessCategory = () => {
  const navigate = useNavigate();
  const categories = [
    { id: 1, name: "Art & Painting", imageUrl: art },
    { id: 2, name: "Baking", imageUrl: baking },
    { id: 3, name: "Boutique", imageUrl: boutique },
    {
      id: 4,
      name: "Clothing & Accessories",
      imageUrl: clothing,
    },
    { id: 5, name: "Fashion & Makeup", imageUrl: fashion },
    { id: 6, name: "Financial Services", imageUrl: financialServices },
    { id: 9, name: "Fitness & Health", imageUrl: fitness },
    { id: 7, name: "Food & Dining", imageUrl: foodDining },
    { id: 8, name: "Homemade Food", imageUrl: homemadeFood },
    { id: 10, name: "Home & Garden", imageUrl: garden },
    {
      id: 11,
      name: "Interior Designing",
      imageUrl: interior,
    },
    {
      id: 12,
      name: "Wedding Items and events",
      imageUrl: weddingDecoration,
    },
    {
      id: 13,
      name: "Teaching",
      imageUrl: teaching,
    },
    {
      id: 14,
      name: "Others",
      imageUrl: others,
    },
  ];

  const map = {
    "Art & Painting": "art",
    Baking: "baking",
    Boutique: "boutique",
    "Clothing & Accessories": "clothing",
    "Fashion & Makeup": "fashion",
    "Financial Services": "finance",
    "Fitness & Health": "health",
    "Food & Dining": "food",
    "Homemade Food": "homemade",
    "Home & Garden": "home",
    "Interior Designing": "interior",
    "Wedding Items and events": "wedding",
    Teaching: "teaching",
    Others: "others",
  };

  // const selectorCategories = [
  //   "All",
  //   "Art & Painting",
  //   "Baking",
  //   "Boutique",
  //   "Clothing & Accessories",
  //   "Fashion & Makeup",
  //   "Financial Services",
  //   "Fitness & Health",
  //   "Food & Dining",
  //   "Homemade Food",
  //   "Home & Garden",
  //   "Interior Designing",
  //   "Wedding Items and Events",
  //   "Teaching",
  //   "Others"
  // ];

  const handleClick = (categoryName) => {
    const mapping = map[categoryName];
    console.log(mapping);
    navigate(`/business/${mapping}`);
  };

  return (
    <div>
      {/* <SearchBar options={selectorCategories} /> */}
      <div className="container mx-auto mt-8">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {categories.map((category) => (
            <div key={category.id} onClick={() => handleClick(category.name)}>
              <CategoryCard
                categoryName={category.name}
                imageUrl={category.imageUrl}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BusinessCategory;

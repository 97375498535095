import { useEffect, useState } from "react";
import Logo1 from "../../assets/logo1.png";
import Logo2 from "../../assets/logo2.png";
import Logo_1 from "../../assets/logo_1.png";
import Logo_2 from "../../assets/logo_2.png";

import Background from "../../assets/background.jpg";

import "./navbar.css";
import { Link } from "react-router-dom";
const Navbar = () => {
  const imgWidth = "250vw";
  const imgHeight = "200vh";
  const smallImgWidth = "100vw";
  const smallImgHeight = "55vh";
  const [navbarOpen, setNavbarOpen] = useState(false);
  const [isSticky, setSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const header = document.getElementById("header");
      const headerRect = header.getBoundingClientRect();

      setSticky(headerRect.bottom <= 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <div
        id="header"
        className="z-1 relative inline-block w-full text-center text-white pt-[3.125rem] px-0 pb-1.5"
        style={{ backgroundImage: `url(${Background})` }}
      >
        <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-80"></div>
        <div className="relative px-0">
          <div className="flex flex-row justify-between align-middle">
            <div className="hidden relative -mt-10 md:block">
              <img src={Logo1} alt="" width={imgWidth} height={imgHeight} />
            </div>
            <div className="block relative -mt-10 md:hidden">
              <img
                src={Logo_1}
                alt=""
                width={smallImgWidth}
                height={smallImgHeight}
              />
            </div>
            <div className=" relative mx-auto mt-2.5 mb-2.5 -translate-y-7">
              <div className="text-[4.5vw] md:text-[3vw] mx-auto my-0 font-bold tagline px-2">
                तेलंगाना आंध्र प्रादेशिक माहेश्वरी महिला संगठन
              </div>
            </div>
            <div className="block relative -mt-10 md:hidden">
              <img
                src={Logo_2}
                alt=""
                width={smallImgWidth}
                height={smallImgHeight}
              />
            </div>
            <div className="hidden relative -mt-[2.5rem] md:block">
              <img src={Logo2} alt="" width={imgWidth} height={imgHeight} />
            </div>
          </div>
        </div>
      </div>
      <div className={`${isSticky && "sticky top-0 w-full"}`}>
        <nav className="z-40 relative flex flex-wrap items-center justify-between px-2 pt-3 pb-1 bg-[#E44C32] mb-3 -mt-8 md:mt-0">
          <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
            <div className="w-full relative flex justify-between items-center md:w-auto md:block md:justify-start">
              <div className="flex flex-col">
                <div className="tagline text-[4.5vw] sm:text-[2vw] font-bold pb-2">
                  स्वयं सिद्धा महिला सशक्तिकरण समिति
                </div>
                {navbarOpen && (
                  <div className="border-t-2 w-full opacity-60 border-[#FFD700] md:hidden"></div>
                )}
              </div>
              <div
                className="text-white cursor-pointer text-xl leading-none z-50 px-3 py-1 border border-solid border-transparent rounded bg-transparent block md:hidden outline-none focus:outline-none"
                onClick={() => setNavbarOpen(!navbarOpen)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="4vh"
                  width="4vw"
                  viewBox="0 0 448 512"
                  fill="gold"
                >
                  <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
                </svg>
              </div>
            </div>
            <div
              className={
                "md:flex flex-grow items-center" +
                (navbarOpen ? " flex" : " hidden")
              }
            >
              <ul className="flex flex-col md:flex-row list-none md:ml-auto">
                <li className="nav-item">
                  <Link
                    className="px-3 py-1 md:py-0 flex items-center text-md md:text-[1.2vw] uppercase font-medium md:font-bold leading-snug text-white hover:opacity-75"
                    to="/"
                    onClick={() => setNavbarOpen(false)}
                  >
                    <span className="ml-2">Home</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="px-3 py-1 md:py-0 flex items-center text-md md:text-[1.2vw] uppercase font-medium md:font-bold leading-snug text-white hover:opacity-75"
                    to="/aboutus"
                    onClick={() => setNavbarOpen(false)}
                  >
                    <span className="ml-2">About Us</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="px-3 py-1 md:py-0 flex items-center text-md md:text-[1.2vw] uppercase font-medium md:font-bold leading-snug text-white hover:opacity-75"
                    to="/business"
                    onClick={() => setNavbarOpen(false)}
                  >
                    <span className="ml-2">Business</span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Navbar;

/* <nav className={`${isSticky && "fixed top-0 w-full"}`}>
        <div className="navbar-items flex justify-center items-center bg-[#E44C32] text-white p-5 transition-all duration-300">
          <a href="#home" className="mx-4 first-link">
            Home
          </a>
          <a href="#about" className="mx-4">
            About Us
          </a>
          <a href="#events" className="mx-4">
            Events
          </a>
          <a href="#business" className="mx-4 last-link">
            Business
          </a>
        </div>
      </nav> */

import React from "react";
import LandingImage from "../../assets/slider01.jpg";

import {
  generalCommitteeMembers,
  headCommitteeMembers,
} from "../../utils/committeData";
import AboutUs from "../AboutUs/AboutUs";
const Landing = () => {
  return (
    <div className="wrapper bg-gray-200 mb-5">
      <div className="block w-100 mx-5 sm:mx-10 h-auto">
        <img src={LandingImage} className="w-full" alt="Lading" />
        <AboutUs />
      </div>

      <div className="card mt-5 bg-white rounded-md py-5 mx-10 my-3 flex flex-wrap justify-center ">
        <div className="text-2xl font-bold w-full flex justify-center underline">
          प्रादेशिक पदाधिकारी
        </div>
        {headCommitteeMembers.map((data) => (
          <div className=" m-5 h-100 items-center rounded-md flex-col justify-center text-center px-5">
            <img
              src={data.image}
              alt="profile"
              className="w-56 h-72 rounded-md shadow-[0_3px_10px_rgb(0,0,0,0.2)]"
            />
            <div className="pt-1 font-semibold">{data.name}</div>
            <div className="text-red-400 font-semibold">{data.position}</div>
          </div>
        ))}
      </div>

      <div className="card mt-5 bg-white rounded-md py-5 mx-10 my-3 flex flex-wrap justify-center ">
        <div className="text-2xl font-bold w-full flex justify-center underline">
          सह संयोजिका
        </div>
        {generalCommitteeMembers.map((data) => (
          <div className=" m-5 h-100 rounded-md flex-col justify-center items-center px-5 text-center">
            <img
              src={data.image}
              alt="profile"
              className="w-48 h-56 sm:w-40 sm:h-52 shadow-[0_3px_10px_rgb(0,0,0,0.2)] rounded-lg"
            />
            <div className="pt-1 font-semibold">{data.name}</div>
            <div className="text-red-400 font-semibold">{data.position}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Landing;

import React from "react";

const Footer = () => {
  return (
    <div className=" block w-full bg-[#E44C32] text-center text-white py-3">
      <div className="flex items-center justify-center">
        महिला सशक्तिकरण समिति ©<span className="text-sm">2024</span>
      </div>
    </div>
  );
};

export default Footer;

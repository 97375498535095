import {
  createBrowserRouter,
  Route,
  RouterProvider,
  Routes,
} from "react-router-dom";
import Landing from "./screens/Home/Landing";
import AboutUs from "./screens/AboutUs/AboutUs";
import Layout from "./compenents/Layout";
import BusinessCategory from "./screens/BusinessCategory/BusinessCategory";
import BusinessPerCategory from "./screens/BusinessesPerCategory/BusinessPerCategory";

// import "react-toastify/dist/ReactToastify.css";
// import { ToastContainer } from "react-toastify";

const router = createBrowserRouter([{ path: "*", Component: Root }]);

function App() {
  return <RouterProvider router={router} />;
}

function Root() {
  return (
    <>
      {/* <ToastContainer /> */}
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" element={<Landing />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/business" element={<BusinessCategory />} />
          <Route path="/business/:category" element={<BusinessPerCategory />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;

import aanchalSoni from "../assets/committee/aanchalSoni.jpg";
import aartiJhawar from "../assets/committee/aartiJhawar.jpg";
import archanaBang from "../assets/committee/archanaBang.jpg";
import archanaSoni from "../assets/committee/archanaSoni.jpg";
import kalaDarak from "../assets/committee/kalaDarak.jpg";
import komalRathi from "../assets/committee/komalRathi.jpg";
import manjuLahoti from "../assets/committee/manjuLahoti.jpg";
import rajniRathi from "../assets/committee/rajniRathi.jpg";
import rekhaJakhotiya from "../assets/committee/rekhaJakhotiya.jpg";
import sapnaKabra from "../assets/committee/sapnaKabra.jpg";
import shrutiBhakkad from "../assets/committee/shrutiBhakkad.jpg";
import sumitraBang from "../assets/committee/sumitraBang.jpg";

export const headCommitteeMembers = [
  {
    name: " रजनी राठी",
    position: "प्रदेश अध्यक्ष",
    image: rajniRathi,
  },
  {
    name: " मंजू लाहोटी",
    position: "प्रदेश सचिव",
    image: manjuLahoti,
  },
  {
    name: " अर्चना राजेश बंग",
    position: "प्रदेश सैयोजीका ",
    image: archanaBang,
  },
];

export const generalCommitteeMembers = [
  {
    name: "आरती झवर",
    image: aartiJhawar,
  },
  {
    name: "श्रुति भक्कड़",
    image: shrutiBhakkad,
  },
  {
    name: "सुमित्रा बंग",
    image: sumitraBang,
  },
  {
    name: "अर्चना रूपेश सोनी",
    image: archanaSoni,
  },
  {
    name: "Komal Rathi",
    image: komalRathi,
  },
  {
    name: "Kala Darak",
    image: kalaDarak,
  },
  {
    name: "Sapna kabra",
    image: sapnaKabra,
  },
  {
    name: "रेखा जखोटिया",
    image: rekhaJakhotiya,
  },
  {
    name: "आंचल सोनी",
    image: aanchalSoni,
  },
];

import React from "react";

const AboutUs = () => {
  return (
    <div className="card mt-5 bg-white w-full rounded-md  px-2 py-5 mb-4">
      <p className="font-bold px-2 py-0 text-lg sm:text-2xl my-2">
        स्वयं सिद्धा महिला सशक्तिकरण समिति
      </p>
      <div className="data text-justify text-sm sm:text-lg p-2 leading-5 tracking-wide">
        "स्वयं सिद्धा महिला सशक्तिकरण समिति" एक सकारात्मक दृष्टिकोण और समर्पित
        समूह है, जो नारी समाज की वह सशक्त धूरी है जिस पर संपूर्ण समाज का विकास
        टिका है। नारी सशक्त तो समाज सशक्त, इसी भाव को ध्यान में रखते हुए हमने
        "स्वयं सिद्धा महिला सशक्तिकरण समिति" का गठन किया है। हमारा मूल उद्देश्य
        है महिलाओं का सर्वांगीण विकास कर, आत्मविश्वासी और आत्मनिर्भर बनाने का
        प्रयास करना है।
      </div>
      <div className="data text-justify text-sm sm:text-lg p-2 leading-5 tracking-wide">
        आर्थिक रूप से आत्मनिर्भर बनाने के लिए समाज की जरूरतमंद बहनों को
        स्वरोजगार करवाने हेतु सामाजिक ट्रस्टो, बैंकों से लोन लेने हेतु
        मार्गदर्शन देना, व्यवसाय स्थापित करने तथा बढ़ाने हेतु उचित जानकारी देना,
        शिक्षा व स्वावलंबन के प्रति प्रोत्साहित करना आदि । श्रंखलाबद्ध संगठन में
        प्रत्येक स्तर पर वोकेशनल ट्रेनिंग सेंटर स्थापित करना, ट्रेड फेयर
        ट्रेनिंग, डिजिटल मार्केटिंग, ब्रांडिंग, शोकेस जैसे विषयों पर विशेषज्ञों
        द्वारा उचित मार्गदर्शन देना। बीमा सुरक्षा बचत योजना के बारे में समझाना।
        घरेलू हिंसा इत्यादि विषयों से संबंधित कानूनी सलाह एवं सहायता प्रदान करना
        ।
      </div>
      <div className="data text-justify text-sm sm:text-lg p-2 leading-5 tracking-wide">
        स्वयं सिद्धा समिति द्वारा किए गए कार्य इस प्रकार रहे है नुक्कड़ नाटक
        द्वारा समिति के कार्य का प्रचार, अनुराधाजी जाजू द्वारा सशक्त सिया
        कार्यक्रम,नव अलंकार कार्यक्रम, मातृ रूपेण= मां तेरे रूप अनेक
        प्रतियोगिता,विद्या रूपेण=अनार्थलय में नृत्य सिखाया गया,क्षुधा रूपेण =
        कुकिंग कार्यशाला,श्रद्धा रूपेण=कन्याओं का श्रृंगार, बुद्धि
        रूपेण=numerology n astrology की जानकारी,स्मृति रूपेण=धार्मिक quiz,दया
        रूपेण =सिलाई मशीन दी गई ,कांति रूपेण =self grooming ,शक्ति रूपेण
        =डांडिया नृत्य प्रतियोगिता ,जन्माष्टमी पर्व पर ठाकुरजी के श्रृंगार की
        कार्यशाला,केश दान अभियान,पुरषोत्तम मास में परमार्थ सेवा, घरेलू हिंसा पर
        कानूनी जानकारी दी गई|
      </div>
    </div>
  );
};

export default AboutUs;

import React from "react";

const CategoryCard = ({ categoryName, imageUrl }) => {
  return (
    <div className="mx-auto mb-4 text-center w-60 h-80">
      <div className="bg-white rounded-lg overflow-hidden h-full">
        <img
          className="w-full h-60 object-cover p-4"
          src={imageUrl}
          alt={categoryName}
        />
        <hr className="h-1.5" />
        <div className="p-4">
          <h3 className="text-xl font-semibold mb-2">{categoryName}</h3>
        </div>
      </div>
    </div>
  );
};

export default CategoryCard;

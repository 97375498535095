import React from "react";
import data from "../../assets/data.json";
import "../../index.css";
import { useParams } from "react-router-dom";

const BusinessPerCategory = () => {
  const { category } = useParams();
  const filteredData = data.filter(
    (item) => item.Category.toLowerCase() === category
  );

  const handlePhoneNumberClick = (phoneNumber) => {
    window.open(`tel:${phoneNumber}`, "_self");
  };

  return filteredData.map((data) => {
    return (
      <div
        className="max-w-lg rounded-3xl flex items-center h-auto flex-wrap mx-auto my-5 shadow-xl"
        key={data["Phone number"]}
      >
        <div className="w-full rounded-lg shadow-2xl bg-white mx-4 lg:mx-0">
          <div className="p-4 md:p-12 lg:text-left">
            <h1 className="text-3xl font-bold pt-4 lg:pt-0 text-center">
              {data.Name}
            </h1>
            <div className="mx-auto lg:mx-0 w-4/5 pt-3 border-b-2 border-teal-200"></div>
            <p className="pt-4 text-base font-bold flex items-center justify-start">
              <img
                className="mr-3"
                src="https://img.icons8.com/dusk/30/000000/new-job.png"
                alt="email"
              />
              {data.Email}
            </p>
            <div className="pt-2 text-gray-600 text-xs lg:text-sm flex items-center justify-start">
              <img
                className="mr-3"
                src="https://img.icons8.com/officel/30/000000/worldwide-location.png"
                alt="location"
              />
              <div className="flex flex-col items-start">
                <div>{data.Address}</div>
                <div>{data.city}</div>
              </div>
            </div>
            <p className="pt-4 text-sm flex items-center justify-start">
              <img
                className="mr-3"
                src="https://img.icons8.com/external-photo3ideastudio-lineal-color-photo3ideastudio/30/external-description-museum-photo3ideastudio-lineal-color-photo3ideastudio.png"
                alt="description"
              />
              {data.description}
            </p>
            {data.Keyword !== "" && (
              <div className="pt-2 text-sm flex items-center justify-start">
                <img
                  className="mr-3"
                  src="https://img.icons8.com/color/30/statistics.png"
                  alt="keywords"
                />

                <div className="bg-green-200 inset-0 bg-blend-lighten rounded-lg px-3 py-1 font-semibold text-green-900 leading-tight">
                  {data.Keyword}
                </div>
              </div>
            )}

            <div className="mx-auto lg:mx-0 w-4/5 pt-3 border-b-2 border-teal-200"></div>

            <div className="pt-2">Contact Us: </div>
            <div className="pt-2 flex justify-between items-baseline">
              <div className="flex justify-start">
                <div>
                  <img
                    className="mr-3"
                    src="https://img.icons8.com/color/30/phone.png"
                    alt="phone"
                  />
                </div>
                <a
                  href={`tel:${data["Phone number"]}`}
                  onClick={() => handlePhoneNumberClick(data["Phone number"])}
                >
                  {data["Phone number"]}
                </a>
              </div>
              <div className="flex justify-start items-start">
                {data.social.includes("facebook") && (
                  <a
                    className="link opacity-100 ml-3"
                    href={data.social}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="https://img.icons8.com/color/35/000000/facebook-circled.png"
                      alt="facebook"
                    />
                  </a>
                )}
                {data.social.includes("instagram") && (
                  <a
                    className="link opacity-100 ml-3"
                    href={data.social}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="https://img.icons8.com/cute-clipart/35/000000/instagram-new.png"
                      alt="instagram"
                    />
                  </a>
                )}
                {data.social.includes("whatsapp") && (
                  <a
                    className="link opacity-100 ml-3"
                    href={data.social}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="https://img.icons8.com/color/35/whatsapp--v1.png"
                      alt="whatsapp"
                    />
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  });
};

export default BusinessPerCategory;
